
import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Box, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import { AccordionItemInterface } from '../Interfaces'

interface AccordionFAQProps {
  items: AccordionItemInterface[]
  mt: {}
}

const AccordionFAQ = (props: AccordionFAQProps) => {

  return (
    <Accordion allowToggle flex="1" p={5} mt={props.mt} >
      {props.items.map(item => (
        <AccordionItem key={item.id} mb={2}>
          <h2>
            <AccordionButton borderColor="bullseyeGreen.500" borderWidth={1} _expanded={{ bg: "bullseyeGreen.500", color: "white" }} >
              <Box flex="1" textAlign="left">
                {item.heading}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {item.text}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

export default AccordionFAQ